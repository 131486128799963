<template>
  <div>
    <a-card :title="$t('移库单')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('移库单号, 备注')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addOrder">
            {{ $t("新增移库单") }}
          </a-button>
        </div>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
          :scroll="{ x: 1020 }"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
              <a-popconfirm :title="$t('确定作废吗?')" :disabled="item.is_void" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{ $t("作废") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { locationTransferOrderList, locationTransferOrderVoid } from "@/api/stockTransfer";
import { columns } from "./columns";
import moment from "moment";
import { warehouseOption } from "@/api/option";

export default {
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1 },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      locationTransferOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addOrder() {
      this.$router.push({ path: "/location_transfer_order_create" });
    },
    detail(item) {
      this.$router.push({ path: "/location_transfer_order_detail", query: { id: item.id } });
    },
    voidItem(item) {
      locationTransferOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
